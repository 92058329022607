.signButton {
    border: none;
    background: transparent;
    padding: 5px 10px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    margin-right: 50px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    border-radius: 8px;

    &:hover {
        color: $color-1;
    }
}