.Header {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 55px;
    width: 100%;
    height: 200px;
    background: $color-4;
    z-index: 10;

    h1 {
        color: #fff;
        width: 250px;
        text-align: center;
    }

    p {
        color: $color-3;
        margin-top: 10px;
    }
}