.connectedMessage {
    width: 100%;
    height: 100vh;
    place-items: center;
    display: grid;
    color: $color-2;
    text-transform: uppercase;
    text-align: center;
    padding: 0 20px;
}
