$poppins: Poppins,sans-serif;
$color-1: #282639;
$color-2: #F47B7B;
$color-3: #D8D8D8;
$color-4: #3A384C;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: $poppins;
}

p, button, input {
    font-size: 16px;
}

body {
    background: $color-1;
}