.NavBar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 55px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    background: $color-2;
    z-index: 11;

    p {
        padding: 5px 10px;
        margin-left: 50px;
        color: #fff;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bold;
    }
}